import { IApiRequest } from '@/gateway/axios/IApiRequest'
import { sentbizApi } from '@/gateway/axios'
import Recipient from '@/models/recipient/Recipient'

export interface GetRecipientsParam {
  corp_id: string;
  corp_pids?: Array<string>;
  recipient_name?: string;
  country?: string;
  page: number;
  unit: number;
}

export interface GetRecipientsResponse {
  list: Array<Recipient>;
  total_count: number;
}

export interface GetRecipients extends IApiRequest {
  getInstance (): GetRecipients;
  request (data: GetRecipientsParam): Promise<GetRecipientsResponse>;
}

export class GetRecipients implements GetRecipients {
  private static instance: GetRecipients
  private constructor () {}
  public static getInstance (): GetRecipients {
    if (!this.instance) this.instance = new GetRecipients()
    return this.instance
  }

  async request (data: GetRecipientsParam): Promise<GetRecipientsResponse> {
    const requestOption: RequestOption = {
      url: 'remittance/recipients',
      method: 'post',
      data
    }
    const response = await sentbizApi.request(requestOption)
    return response?.data
  }
}
